import { create } from 'zustand'

export default create((set, get) => ({
  tripTraveler: {},
  tripTravelerError: false,
  flights: null,
  setFlights: (flights) => set({flights}),
  flightStatsFlights: [],
  setFlightStatsFlights: (flightStatsFlights) => set({ flightStatsFlights }),
  selectedFlights: [],
  setSelectedFlights: (selectedFlights) => set({ selectedFlights }),
  selectedTravelers: [],
  setSelectedTravelers: (selectedTravelers) => set({ selectedTravelers }),
  addAnotherFlight: 0, 
  setAddAnotherFlight: (addAnotherFlight) => set({ addAnotherFlight }),
  loadingTripTraveler: true,
  loadedTripTraveler: false,
  clearError: () => set({tripTravelerError: false}),
  setTripTraveler(tripTraveler) {
    set({tripTraveler})
  },
  fetchTripTraveler: async (id) => {
    set({loadingTripTraveler: true, loadedTripTraveler: true})
    try {
      const res = await fetch(`/api/travelers/${id}.json`)
      if (!res.ok) throw new Error('Failed to fetch traveler')
      const json = await res.json()
      set({
        tripTraveler: json,
      })
    } catch (error) {
      set({tripTravelerError: true, loadedTripTraveler: false})
    }
    set({
      loadingTripTraveler: false,
      flights: {
        pre_trip_flights: get().tripTraveler.pre_trip_flights,
        on_trip_flights: get().tripTraveler.on_trip_flights,
        post_trip_flights: get().tripTraveler.post_trip_flights,
      }
    })
  },
  viewedDocs: () => {
    const traveler = {...get().tripTraveler}
    fetch(`/api/travelers/${traveler.record_id}/documents.json`)
      .then(() => {
        traveler.new_docs_count = 0
        set({tripTraveler: traveler})
      })
  }
}))
